import React, { useState, useEffect, useRef } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import { Tabs, Tab, TabPanel, TabList, TabProvider } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import {
  ArrowRightICon,
  MobNextArrowIcon,
} from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import Cards from "../../components/chat-channel/Cards";
import useInterval from "../../react/useInterval";
import { useSwipeable, Swipeable } from "react-swipeable";
import { FeatureArrowWithColor } from "../../components/common/Icons";
import ReactBootstrapCarousel from "react-bootstrap-carousel";
import { useMedia } from "use-media";

import { VideoElement2 } from "../../components/video";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";
import { RightArrowIconAssistants } from "../../components/common/Icons";
import {
  AdHomePage,
  AdHomePageMobile,
  Onboarding,
  OnboardingMobile,
  AppsIntegrations,
  ISOSecurity,
  HrItSupport,
  HrItSupportMobile,
  usePreloadImages,
} from "../conversational-new";

const customerBanner = require("../../assets/img/feature/home/header_customer_banner.svg");
const bannerImageMobile = require("../../assets/img/feature/home/clients_logo_mob.svg");

const it_features_one = require("../../assets/img/home_page_assist/it_features_1.png");
const it_features_three = require("../../assets/img/home_page_assist/it_features_3.png");
const it_features_four = require("../../assets/img/home_page_assist/it_features_4.png");
const it_features_five = require("../../assets/img/home_page_assist/it_features_5.png");
const it_features_six = require("../../assets/img/home_page_assist/it_features_6.png");
const it_features_seven = require("../../assets/img/home_page_assist/it_features_7.png");
const it_features_eight = require("../../assets/img/home_page_assist/it_features_8.png");

const workflow_arrow = require("../../assets/img/home_page_assist/workflow_arrow.svg");
const arrow = require("../../assets/img/home_page_assist/arrow.svg");

const onboard_one = require("../../assets/img/home_page_assist/onboarding_1.png");
const onboard_two = require("../../assets/img/home_page_assist/onboarding_2.png");
const onboard_three = require("../../assets/img/home_page_assist/onboarding_3.png");
const onboard_four = require("../../assets/img/home_page_assist/onboarding_4.png");
const onboard_five = require("../../assets/img/home_page_assist/onboarding_5.png");

const arrow_round_mobile = require("../../assets/img/home_page_assist/left_arrow_mobile.svg");

const onboard_one_mob = require("../../assets/img/home_page_assist/onboarding_1_mob.png");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

const reviewer_image = require("../../assets/images/testimonials/reviewer_circle.png");
const reviewer_img_mobile = require("../../assets/images/testimonials/review_hammer.png");
const hammer_logo = require("../../assets/images/testimonials/hammer_logo.svg");
const star_image = require("../../assets/images/testimonials/star.svg");
const capterra_logo = require("../../assets/images/testimonials/capterra_logo.svg");
const g2_logo = require("../../assets/images/testimonials/g2_logo.svg");
const quotes = require("../../assets/images/testimonials/quotes.svg");
const goto_manager = require("../../assets/images/testimonials/goto_manager_2.png");
const goto_manager_mobile = require("../../assets/images/testimonials/goto_manager_1.png");
const goto_logo = require("../../assets/images/testimonials/goto_logo.svg");
const hammer_manager_mobile = require("../../assets/images/testimonials/reviewer_circle.png");

const unlock_account = require("../../assets/img/home_page_assist/it_workflow_1.png");
const password_resets = require("../../assets/img/home_page_assist/it_workflow_2.png");
const user_provisioning = require("../../assets/img/home_page_assist/it_workflow_3.png");
const access_management = require("../../assets/img/home_page_assist/it_workflow_4.png");
const asset_requests = require("../../assets/img/home_page_assist/it_workflow_6.png");
const onboarding = require("../../assets/img/home_page_assist/it_workflow_7.png");
const offboarding = require("../../assets/img/home_page_assist/it_workflow_8.png");

const topImage = require("../../assets/img/home_page_assist/it_bot_header.png");
const VidImg = require("../../assets/img/chatbot/it_chatbot/vid_img_it_bot.png");
const VidImgMob = require("../../assets/img/home_page_assist/it.png");

const slides = [
  {
    image: unlock_account,
    title: "Unlock Account",
    tabFor: "vertical-tab-one",
    text: "unlock account",
  },
  {
    image: password_resets,
    title: "Password Reset",
    tabFor: "vertical-tab-two",
    text: "password resets",
  },
  {
    image: user_provisioning,
    title: "User Provisioning",
    tabFor: "vertical-tab-three",
    text: "user provisioning",
  },
  {
    image: access_management,
    title: "Access Management",
    tabFor: "vertical-tab-four",
    text: "access management",
  },
  {
    image: asset_requests,
    title: "Asset Requests",
    tabFor: "vertical-tab-five",
    text: "asset requests",
  },
  {
    image: onboarding,
    title: "Onboarding",
    tabFor: "vertical-tab-six",
    text: "employee onboarding",
  },
  {
    image: offboarding,
    title: "Offboarding",
    tabFor: "vertical-tab-seven",
    text: "employee offboarding",
  },
];

const buttonMenu = [
  {
    tabFor1: "vertical-tab-one",
    tabName: "unlock account",
  },
  {
    tabFor1: "vertical-tab-two",
    tabName: "password resets",
  },
  {
    tabFor1: "vertical-tab-three",
    tabName: "user provisioning",
  },
  {
    tabFor1: "vertical-tab-four",
    tabName: "access management",
  },
  {
    tabFor1: "vertical-tab-five",
    tabName: "asset requests",
  },
  {
    tabFor1: "vertical-tab-six",
    tabName: "employee onboarding",
  },
  {
    tabFor1: "vertical-tab-seven",
    tabName: "employee offboarding",
  },
];

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Free AI Self-Service Chatbot| Workativ Assistant Chatbot"
        description="Use AI Self Service Chatbot to automate workplace support. Build ai based self-service helpdesk using chatbot easily in minutes. Reinvent your workplace HR &amp; IT support instantly."
        keywords={[
          "WHR chatbot ",
          "AI Chatbot",
          "IT Helpdesk chatbot",
          "Automation",
        ]}
        ogImage={topImage}
        ogTitle="Free AI Self-Service Chatbot| Workativ Assistant Chatbot"
        ogDescription="Use AI Self Service Chatbot to automate workplace support. Build ai based self-service helpdesk using chatbot easily in minutes. Reinvent your workplace HR &amp; IT support instantly. "
      />
      <section className="it_support_chatbot container_trial integrations_container landing_page_chatbot support_chatbot_it_help">
        <Container>
          <Layout backgroundColor={"landing_bg"} logoFor="ASSISTANT">
            <RegisterSection
              rightImage={topImage}
              backgroundColor={"landing_bg"}
              altImage={"IT helpdesk automation"}
              additionalClass={"padding-top-15 it_support_new_page"}
              iD={"chatbot"}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  Empower your employees with AI Self Service using advanced NLP
                  and workflow automation
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Workativ helps you automate most of your employee enquires and
                  service requests using AI Self Service with in-built workflow
                  automation technology.
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>
            <div
              className={`float-left w-100 ${isSmall ? "mb-4" : "mb-5 mt-5"}`}
            >
              <div className="container">
                <h5
                  className={`text-center font-section-normal-text-medium-customer-banner it-help-title ${
                    isSmall ? "mb-3" : "margin-bottom-20 "
                  }`}
                >
                  Join hundreds of industry leaders
                </h5>{" "}
                {isSmall ? (
                  <img className="" src={bannerImageMobile} />
                ) : (
                  <img className="w-90 d-flex m-auto" src={customerBanner} />
                )}
              </div>
            </div>
            {isSmall ? (
              <HrItSupportMobile
                tabContent={tabsForHrIt}
                imagesToPreload={tabsForHrItImages}
                header={"AI Self Service Capabilities"}
              />
            ) : (
              <HrItSupport
                tabContent={tabsForHrIt}
                imagesToPreload={tabsForHrItImages}
                header={"AI Self Service Capabilities"}
              />
            )}
            <AdHomePage />
            {isSmall ? (
              <>
                <OnboardingMobile
                  tabContent={tabsForOnBoarding}
                  imagesToPreload={tabsForOnBoardingImages}
                />
                <TestimonialsSupportMobile />
              </>
            ) : (
              <OnboardingTestimonialsSupport
                imagesToPreload={tabsForOnBoardingImages}
              />
            )}
            <RequestForm
              isFooterForm={true}
              additionalClass={isSmall ? "mt-0" : "mt-5"}
            />

            <AppsIntegrations
              header={
                isSmall ? (
                  <h3 className="font-section-sub-header mb-3">
                    Connect your Self-Service with 100+ apps, 600+ actions, and
                    1000s of automations instantly. No coding
                  </h3>
                ) : (
                  <>
                    <h3 className="font-section-sub-header mb-2">
                      Connect your Self-Service with 100+ apps, 600+ actions,
                      and 1000s of automations instantly. No coding
                    </h3>
                  </>
                )
              }
              additionalClass={"mb-5"}
              footer={
                <>
                  Also available CRM, Expense Management, Document Management,
                  Project Management, Email Management, SMS Management,
                  Notifications, and Collaboration Apps.
                </>
              }
            />
            <ISOSecurity />
            <div className="container">
              <div className="usecase_blog_container">
                <h3 className="font-section-sub-header-small-bold-v2">
                  Must Read Blogs
                </h3>
                <div className="usecase_bottom_blog">
                  <ul>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot"
                      >
                        What Is Conversational AI? A Guide to Conversational AI
                        Chatbots
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/onboarding-challenges-it-service-desk-face-with-new-agents"
                      >
                        Onboarding Challenges IT Service Desk Face with New
                        Agents
                      </a>
                    </li>
                    <li>
                      <a
                        className="blog_usecase_link font-section-normal-text"
                        href="https://workativ.com/conversational-ai-platform/leveraging-the-value-of-ai-self-service-workativ"
                      >
                        Leveraging the Value of AI Self-Service – Workativ
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <GoToPopup />
            <CookiesPoup />
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

function OnboardingTestimonialsSupport() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="onboarding_testimoanials_main_container w-100 float-left">
      <Onboarding tabContent={tabsForOnBoarding} />
      <div className="container">
        <div className="row">
          <div className="main_container_testimonials_usecase testimonials_container_support">
            <div className="top_image_container_testimonials_usecase top_image_container_testimonials_it_support">
              <img
                loading="lazy"
                className="capterra_logo_left capterra_usecase"
                src={capterra_logo}
              />
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <img
                loading="lazy"
                src={star_image}
                className="rating_star_image"
              ></img>
              <span>5/5</span>
            </div>
            <div className="middle_content_container_testimonials_usecase">
              <h5 className="font-section-sub-header">
                “Workativ delivers and the implementation team is top notch.
                Very supportive and responsive to question”
              </h5>
              <p className="font-section-normal-text-testimonials">
                <span className="font-section-normal-text-medium color-black">
                  Overall:&nbsp;
                </span>
                Very positive experience. We are able to grow the bot based on
                our internal needs. The cost and support can’t be beat for all
                the features the tool provides
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-medium color-black">
                  Pros:&nbsp;
                </span>
                It just seems to work out of the box. If you have a decent
                outline of the needs the bot needs to fill it is really
                straightforward to set up and test. Their deployment team offers
                insight on where to focus time and provides resources to go to
                quickly resolve questions on your own. Truly self service.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-medium color-black">
                  Cons:&nbsp;
                </span>
                No complaints right now - when we have had issues they respond
                quickly with a plan and their follow through is excellent
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-medium color-black">
                  Reasons for Choosing Workativ Assistant:&nbsp;
                </span>
                Cost and the willingness of Workativ to answer questions and
                ensure our internal business requirements were being met.
              </p>
            </div>
            <div className="bottom_button_content_container_testimonials">
              <div className="bottom_reviewer_image_container_testimonials_usecase">
                <img
                  loading="lazy"
                  src={reviewer_image}
                  className="testimonial_reviewer_image_landing_page"
                  alt="hammer_manager"
                ></img>
                <div className="end_name_usecase">
                  <h5
                    className="font-section-sub-header-bold-goto-case hammer_dir_name mb-0"
                    style={{ color: "#4F75D9" }}
                  >
                    Dan Bottiger
                  </h5>
                  <h5 className="font-section-normal-text-medium reviewer_designation_hammer mb-0">
                    Director of Information Systems
                  </h5>

                  <img
                    src={hammer_logo}
                    className="it_support_hammer_logo h-auto"
                  ></img>
                </div>
              </div>
              <button
                className="goto_case_study_button_redirection button_usecase_it_support w-30 mt-3 h-fit-content"
                onClick={() => {
                  window.location.href =
                    "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation";
                }}
              >
                <span className="font-section-normal-text-medium">
                  Explore success stories
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function TestimonialsSupportMobile() {
  return (
    <section class="testimonial_background_main_container_mobile it_support_mobile_testimonials float-left">
      <h3 className="font-section-sub-header text-align-center mb-3">
        Customer testimonial
      </h3>
      <div className="testimonial_feedback_box_mobile home_assistant_tesimonials_content_mobile">
        <div className="testimonial_feedback_top_content_box_mobile">
          <p className="font-section-normal-text-testimonials text-align-left line-height-18">
            <span>
              <img
                src={quotes}
                className="quotes_image_testimonial h-auto"
              ></img>
            </span>
            The setup and building of the Workativ Chatbot I felt was extremely
            well supported! My questions were answered quickly and at no point
            did I feel unsupported. I had multiple Zoom meetings through the
            process where we would meet to go through the build.
          </p>
          <p className="font-section-normal-text-testimonials text-align-left line-height-18">
            When the build hit a hurdle, the resolution was found and
            implemented within 24-48 hours. Through this communication was
            excellent.
          </p>
          <p className="font-section-normal-text-testimonials text-align-left line-height-18 mb-2">
            I felt listened through what we absolutely had to have for this tool
            to be rolled out in our environment. Throughout the process we kept
            looking back at the business requirements to make sure we were
            aligning properly.
          </p>
        </div>

        <img
          src={hammer_manager_mobile}
          className="testimonial_reviewer_image h-auto"
          alt="hammer_global_manager"
          style={{ width: "80px" }}
        ></img>

        <div className="bottom_designation_box_right_mobile ">
          <h5
            className="font-section-sub-header-smaller-bold-testimonials mt-3"
            style={{ color: "#4F75D9" }}
          >
            Dan Bottiger
          </h5>
          <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
            Director of Information Systems
          </h5>
          <img src={hammer_logo} className="w-35 h-auto"></img>
        </div>
        <button
          className="goto_case_study_button_redirection w-100 mt-3"
          onClick={() => {
            window.location.href =
              "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation";
          }}
        >
          <span className="font-section-normal-text-medium">
            Explore success stories
          </span>
        </button>
      </div>
    </section>
  );
}

const tabsForOnBoarding = [
  {
    header: "Sign up",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Sign up</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            You can create a new bot or download pre-built AI Self Service
            Chatbot from marketplace.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_one} alt="Sign up"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Sign up</h3>
        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
          You can create a new bot or download pre-built AI Self Service Chatbot
          from marketplace.
        </p>
        <img src={onboard_one_mob} alt="Sign up"></img>
      </>
    ),
  },
  {
    header: "Download Bot",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Download AI Self Service Chatbot
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Bot templates are pre-built bots with conversations covering most
            common and repetitive support topics.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Easily download Self Service Chatbot in one-click, add new or edit
            existing support topics in minutes.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_two} alt="Bot Marketplace"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">
          Download AI Self Service Chatbot
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Bot templates are pre-built bots with conversations covering most
          common and repetitive support topics.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Easily download Self Service Chatbot in one-click, add new or edit
          existing support topics in minutes.
        </p>
        <img src={onboard_two} alt="Bot Marketplace"></img>
      </>
    ),
  },
  {
    header: "Connect App Workflows",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">
            Connect App Workflows
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Connecting chatbot with your apps and building app workflows
            requires a lot of development efforts, time, and costs.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            But workativ provides pre-built app integrations and app workflows
            so you can connect your chatbot with your app and automate tasks
            instantly. No coding.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_three} alt="Connect App Workflows"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Connect App Workflows</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Connecting chatbot with your apps and building app workflows requires
          a lot of development efforts, time, and costs.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          But workativ provides pre-built app integrations and app workflows so
          you can connect your chatbot with your app and automate tasks
          instantly. No coding.
        </p>

        <img src={onboard_three} alt="Connect App Workflows"></img>
      </>
    ),
  },
  {
    header: "Test your Bot",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container">
          <h3 className="font-section-normal-text-medium-link">Bot Testing</h3>
          <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
            Test your bot in real time using “Try Me”. Make adjustments, add or
            edit conversations, fine tune user interactions, and review your bot
            conversations on the go before you go-live.
          </p>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_four} alt="Test your Bot"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">Bot Testing</h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Test your bot in real time using “Try Me”. Make adjustments, add or
          edit conversations, fine tune user interactions, and review your bot
          conversations on the go before you go-live.
        </p>
        <img src={onboard_four} alt="Test your Bot"></img>
      </>
    ),
  },
  {
    header: "Go Live",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="onboard_content_left_container pr-0">
          <h3 className="font-section-normal-text-medium-link">
            Deploy on Teams, Slack, or as Chat Widget
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Turn your favorite collaboration app into modern employee
            self-service by deploying chatbot on your Teams, Slack or as Widget
            on your self-help portal in few clicks.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18">
            Customize the end-user experience by applying your company branding,
            logo etc.
          </p>
          <div className="icons_container_onboarding">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="onboard_content_right_container">
          <img src={onboard_five} alt="Go Live"></img>
        </div>{" "}
      </React.Fragment>
    ),
    mobileContent: () => (
      <>
        <h3 className="font-section-sub-header">
          Deploy on Teams, Slack, or as Chat Widget
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Turn your favorite collaboration app into modern employee self-service
          by deploying chatbot on your Teams, Slack or as Widget on your
          self-help portal in few clicks.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          Customize the end-user experience by applying your company branding,
          logo etc.
        </p>
        <div className="icons_container_onboarding mb-3">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={onboard_five} alt="Go Live"></img>
      </>
    ),
  },
];

const tabsForOnBoardingImages = [
  onboard_one,
  onboard_two,
  onboard_three,
  onboard_four,
  onboard_five,
  onboard_one_mob,
];

const tabsForHrIt = [
  {
    header: "IT Helpdesk Chatbot",
    desktopContent: () => (
      <React.Fragment>
        <div className="support_content_left_container it_support_left_content_container padding-bottom-40">
          <h3 className="font-section-normal-text-medium-link">
            What is AI Self-Service?
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            AI Self Service in the context of workplace support refers to the
            next generation of technologies available to deliver modern end user
            support autonomously. An AI based self-service approach allows users
            to quickly search for information in knowledge base or perform
            automated tasks and app workflows using Conversational AI and
            Natural Language Processing (NLP) technologies. Using conversational
            AI interfaces such as Chatbots or Digital Assistant, users can chat
            with the “system” as though they would speak with a live agent via
            chat or phone except the “system” here is AI. AI Self Service in
            workplace support enables end users to perform several workplace IT
            and HR related tasks such as changing passwords, unlock account,
            onboarding users, get access to applications, search documents
            without ever calling IT Help Desk.
          </p>
        </div>
        <div className="support_content_right_container align-self-end padding-bottom-40">
          <h3 className="font-section-normal-text-medium-link">
            1. Self-Service or Chatbots:
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Chatbots use Natural Language Processing (NLP) to understand user
            chats and respond back via chat conversations. E.g. end-user
            searching for leave policy may simply ask the Chabot with “what is
            my leave balance”.
          </p>
          <h3 className="font-section-normal-text-medium-link">
            2. Self-Service Automation:
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            These are more popular and well suited for workplace as the focus is
            more on tasks and workflows. Employees via Conversational Automation
            or Tasks based Chatbots can quickly resolve workplace issues and
            requests instantly via app workflow automation technology inbuilt
            into chatbot. This delivers true end-to-end automation for companies
            who are looking to go beyond just the conversational ai
            capabilities.
          </p>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          What is AI Self-Service?
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          AI Self Service in the context of workplace support refers to the next
          generation of technologies available to deliver modern end user
          support autonomously. An AI based self-service approach allows users
          to quickly search for information in knowledge base or perform
          automated tasks and app workflows using Conversational AI and Natural
          Language Processing (NLP) technologies. Using conversational AI
          interfaces such as Chatbots or Digital Assistant, users can chat with
          the “system” as though they would speak with a live agent via chat or
          phone except the “system” here is AI. AI Self Service in workplace
          support enables end users to perform several workplace IT and HR
          related tasks such as changing passwords, unlock account, onboarding
          users, get access to applications, search documents without ever
          calling IT Help Desk.
        </p>
        <h3 className="font-section-normal-text-medium-customer-banner">
          1. Self-Service or Chatbots:
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Chatbots use Natural Language Processing (NLP) to understand user
          chats and respond back via chat conversations. E.g. end-user searching
          for leave policy may simply ask the Chabot with “what is my leave
          balance”.
        </p>
        <h3 className="font-section-normal-text-medium-customer-banner">
          2. Self-Service Automation:
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          These are more popular and well suited for workplace as the focus is
          more on tasks and workflows. Employees via Conversational Automation
          or Tasks based Chatbots can quickly resolve workplace issues and
          requests instantly via app workflow automation technology inbuilt into
          chatbot. This delivers true end-to-end automation for companies who
          are looking to go beyond just the conversational ai capabilities.
        </p>
      </React.Fragment>
    ),
  },
  {
    header: "AI Self-Service Chatbot",
    hasZeroPaddedDesktopImage: true,
    hasZeroPaddedDesktopImageRight:true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Deliver AI Self-service using Conversational AI Chatbot
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Our platform enables you to build chatbots in minutes. Adding,
            removing and or training new skills or topics is easy, and no coding
            required. Let employees resolve their own workplace issues
            autonomously without contacting agents.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Go AI Self-service with Chatbot to deliver remote employee support –
            faster, smarter, and easier.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={it_features_three} alt="Bot"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner ">
          Deliver AI Self-service using Conversational AI Chatbot
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Our platform enables you to build chatbots in minutes. Adding,
          removing and or training new skills or topics is easy, and no coding
          required. Let employees resolve their own workplace issues
          autonomously without contacting agents.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Go AI Self-service with Chatbot to deliver remote employee support –
          faster, smarter, and easier.
        </p>
        <img src={it_features_three} alt="Bot"></img>
      </React.Fragment>
    ),
  },
  {
    header: "App Workflow Automation",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <SectionWithImagesWithExternalClick slides={slides} />
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <SectionWithImagesWithExternalClickMobile slides={slides} />
      </React.Fragment>
    ),
  },
  {
    header: "Omnichannel",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Deploy AI Self Service on your Slack, Teams, or as a Chat Widget in
            few clicks
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Companies using Self-Service Chatbot have seen to automate 60% of
            issues & requests, and scale support much faster.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Delight your employees with always-on 24/7, follow-me,
            auto-resolution self-service, from the comfort of their favourite
            apps like Slack, Teams, or a Chat Widget on Self-help portal.
          </p>

          <div className="icons_container_onboarding mt-0">
            <div className="icon_text">
              <img
                loading="lazy"
                class="slack_feature_icon_onboarding"
                src={FeatureSlack}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Slack
              </h6>
            </div>

            <div className="icon_text">
              <img
                loading="lazy"
                class="teams_feature_icon_onboarding"
                src={FeatureTeams}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Teams
              </h6>
            </div>
            <div className="icon_text">
              <img
                loading="lazy"
                class="chat_feature_icon_onboarding"
                src={FeatureChat}
              ></img>
              <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
                Chat Widget
              </h6>
            </div>
          </div>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={it_features_four} alt="Omnichannel"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Deploy AI Self Service on your Slack, Teams, or as a Chat Widget in
          few clicks
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Companies using Self-Service Chatbot have seen to automate 60% of
          issues & requests, and scale support much faster.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
          Delight your employees with always-on 24/7, follow-me, auto-resolution
          self-service, from the comfort of their favourite apps like Slack,
          Teams, or a Chat Widget on Self-help portal.
        </p>
        <div className="icons_container_onboarding mt-2 mb-4">
          <div className="icon_text">
            <img
              loading="lazy"
              class="slack_feature_icon_onboarding"
              src={FeatureSlack}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Slack
            </h6>
          </div>

          <div className="icon_text">
            <img
              loading="lazy"
              class="teams_feature_icon_onboarding"
              src={FeatureTeams}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Teams
            </h6>
          </div>
          <div className="icon_text">
            <img
              loading="lazy"
              class="chat_feature_icon_onboarding"
              src={FeatureChat}
            ></img>
            <h6 className="font-section-small-text-medium-apps color-light-black mb-0 pt-1">
              Chat Widget
            </h6>
          </div>
        </div>
        <img src={it_features_four} alt="Omnichannel"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Agent Handover",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Live Agent Handover
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Workativ offers seamless{" "}
            <b className="color-light-black">‘agent handover’</b> of chatbot to
            live agents with complete context and user conversation history so
            your agents can pick up right where the bot left, avoiding any user
            frustration – but most importantly solving end-user issues right
            away.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Make your MS Teams a live agent channel instantly.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={it_features_five} alt="Agent Handover"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Live Agent Handover
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Workativ offers seamless{" "}
          <b className="color-light-black">‘agent handover’</b> of chatbot to
          live agents with complete context and user conversation history so
          your agents can pick up right where the bot left, avoiding any user
          frustration – but most importantly solving end-user issues right away.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Make your MS Teams a live agent channel instantly.
        </p>
        <img src={it_features_five} alt="Agent Handover"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Analytics",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Analytics & logs - deep dive on chatbot interactions
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Great dashboards are useless, said no-one ever. Monitor your chatbot
            performance easily from a single pane of glass.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Review chatbot usage, sessions, workflows, logs, and more.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={it_features_six} alt="Analytics"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Analytics & logs - deep dive on chatbot interactions
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Great dashboards are useless, said no-one ever. Monitor your chatbot
          performance easily from a single pane of glass.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Review chatbot usage, sessions, workflows, logs, and more.
        </p>
        <img src={it_features_six} alt="Analytics"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Approvals",
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container">
          <h3 className="font-section-normal-text-medium-link">
            Approve your chatbot requests in seconds
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Automating workplace requests can sometimes require manager or
            special approval. Workativ helps you automate and manage approvals
            for employee requests from chatbot easily out-of-the box with
            approval management capability.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Setting up approvals, notification, reminders, expiry, and tracking
            made simple for you.
          </p>
        </div>
        <div className="support_content_right_container it_support_right_content_container">
          <img src={it_features_seven} alt="Approvals"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Approve your chatbot requests in seconds
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Automating workplace requests can sometimes require manager or special
          approval. Workativ helps you automate and manage approvals for
          employee requests from chatbot easily out-of-the box with approval
          management capability.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Setting up approvals, notification, reminders, expiry, and tracking
          made simple for you.
        </p>
        <img src={it_features_seven} alt="Approvals"></img>
      </React.Fragment>
    ),
  },
  {
    header: "Notifications",
    hasZeroPaddedDesktopImage: true,
    desktopContent: () => (
      <React.Fragment>
        {" "}
        <div className="support_content_left_container it_support_left_content_container padding-bottom-40">
          <h3 className="font-section-normal-text-medium-link">
            Dynamic Notifications
          </h3>
          <p className="font-section-normal-text-testimonials line-height-18">
            Proactively notify users on chat to take action at workplace. Be it
            updating their passwords, filling surveys, updating OKRs, personal
            info, or simply surprise them on their birthdays/anniversaries, etc.
            Never let your employees miss a beat.
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Bot notifications can be set up using app triggers such as a
            birthday events, or new employee onboarding/offboarding or simply
            when a ticket is updated/ closed in your ticketing system. Endless
            possibilities.
          </p>
        </div>
        <div className="support_content_right_container align-self-end pb-0">
          <img src={it_features_eight} alt="Notifications"></img>
        </div>
      </React.Fragment>
    ),
    mobileContent: () => (
      <React.Fragment>
        {" "}
        <h3 className="font-section-normal-text-medium-customer-banner">
          Dynamic Notifications
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18 pb-1">
          Proactively notify users on chat to take action at workplace. Be it
          updating their passwords, filling surveys, updating OKRs, personal
          info, or simply surprise them on their birthdays/anniversaries, etc.
          Never let your employees miss a beat.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0">
          Bot notifications can be set up using app triggers such as a birthday
          events, or new employee onboarding/offboarding or simply when a ticket
          is updated/ closed in your ticketing system. Endless possibilities.
        </p>
        <img src={it_features_eight} alt="Notifications"></img>
      </React.Fragment>
    ),
  },
];

const tabsForHrItImages = [
  it_features_one,
  it_features_three,
  it_features_four,
  it_features_five,
  it_features_six,
  it_features_seven,
  it_features_eight,
  unlock_account,
];

export function tabSliderInit(tabData) {
  return { activeIndex: 0, totalTabs: tabData.length };
}

export const tabSliderReducerWithLoop = (state, action) => {
  // console.log("DOING ::: reducer ", state, action)
  switch (action.type) {
    case "to": {
      // console.log("DOING ::: reducer :: 1", state, action, action.toTab < state.totalTabs, action.toTab >= 0)
      if (action.toTab < state.totalTabs && action.toTab >= 0) {
        const toReturn = { ...state, activeIndex: action.toTab };
        // console.log("DOING ::: reducer :: 2", toReturn)
        return toReturn;
      }
      // console.log("DOING ::: reducer :: 3");
      return state;
    }
    case "next":
      if (state.activeIndex == state.totalTabs - 1) {
        return { ...state, activeIndex: 0 };
      }

      if (state.activeIndex < state.totalTabs - 1) {
        return { ...state, activeIndex: state.activeIndex + 1 };
      }
      return state;
    case "prev":
      if (state.activeIndex == 0) {
        return { ...state, activeIndex: state.totalTabs - 1 };
      }
      if (state.activeIndex < state.totalTabs && state.activeIndex > 0) {
        return { ...state, activeIndex: state.activeIndex - 1 };
      }
      return state;
    case "reset":
      return { ...state, activeIndex: 0 };
    default:
      throw new Error("Invalid action");
  }
};

export function SectionWithImagesWithExternalClick({ slides, buttonMenu }) {
  const [currentTabState, dispatchTabAction] = React.useReducer(
    tabSliderReducerWithLoop,
    slides,
    tabSliderInit
  );

  // const accordElem = React.useRef(null);

  const imageToDisplay = slides[currentTabState.activeIndex].image;

  const altTag = slides[currentTabState.activeIndex].title;

  const allTitles = slides.map((slide) => slide.title);
  const allImages = slides.map((slide) => slide.image);
  usePreloadImages(allImages);

  // const allText = slides.map((slide) => slide.text);
  return (
    <>
      <div className="support_content_left_container it_support_left_content_container">
        {" "}
        <h3 className="font-section-normal-text-medium-link">
          Drive efficiency in your IT Helpdesk with employee service automation
        </h3>
        <p className="font-section-normal-text-testimonials line-height-18">
          Workativ’s one-click integration and out of the box workflows with
          your existing apps will enable true AI self-service automation.
          Automate employee issues & requests such as &nbsp;
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 0 })}
          >
            Salary Enquires
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 1 })}
          >
            Apply Leave
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 2 })}
          >
            Expense Claims
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 3 })}
          >
            Policy Searches
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 5 })}
          >
            {" "}
            employee onboarding
          </span>
          ,{" "}
          <span
            className="external_slider_links"
            onClick={() => dispatchTabAction({ type: "to", toTab: 6 })}
          >
            {" "}
            employee offboarding
          </span>{" "}
          exit feedbacks and much more.
        </p>
        <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
          Make self-service meaningful. Add app workflows so your self-service
          can auto-resolve issues.
        </p>
      </div>
      <div className="right_container_tab_slider_it_suuport">
        <div className="left_cards_tab_appworkflow">
          <h5 className="font-name-smaller-text text-align-center mb-0">
            Click below to view<br></br> different usecase
          </h5>

          <img src={workflow_arrow} className="workflow_arrow_svg"></img>
          <div className="cards_all_main_container_app_workflow">
            {allTitles.map((title, i) => (
              <div
                className={`cards_single_main_container_app_workflow cursor-pointer ${
                  i === currentTabState.activeIndex
                    ? "selected_tab_workflow"
                    : ""
                }`}
                onClick={() => dispatchTabAction({ type: "to", toTab: i })}
              >
                <h5 className="font-name-smaller-text mb-0">{title}</h5>{" "}
                <img src={arrow}></img>
              </div>
            ))}

            {/* <div className="cards_single_main_container_app_workflow selected_tab_workflow">
              <h5 className="font-name-smaller-text mb-0">Reset Password</h5>
              <img src={arrow}></img>
            </div>
            <div className="cards_single_main_container_app_workflow">
              <h5 className="font-name-smaller-text mb-0">User Provisioning</h5>
              <img src={arrow}></img>
            </div>
            <div className="cards_single_main_container_app_workflow">
              <h5 className="font-name-smaller-text mb-0">Access Management</h5>
              <img src={arrow}></img>
            </div>
            <div className="cards_single_main_container_app_workflow">
              <h5 className="font-name-smaller-text mb-0">Asset Requests</h5>
              <img src={arrow}></img>
            </div>
            <div className="cards_single_main_container_app_workflow">
              <h5 className="font-name-smaller-text mb-0">Onboarding</h5>
              <img src={arrow}></img>
            </div>
            <div className="cards_single_main_container_app_workflow border-bottom-none">
              <h5 className="font-name-smaller-text mb-0">Offboarding</h5>
              <img src={arrow}></img>
            </div> */}
          </div>
        </div>
        <div className="right_cards_tab_appworkflow">
          <img classname="w-100" src={imageToDisplay} alt={altTag}></img>
        </div>
      </div>
    </>
  );
}

export function SectionWithImagesWithExternalClickMobile({ slides }) {
  const [currentTabState, dispatchTabAction] = React.useReducer(
    tabSliderReducerWithLoop,
    slides,
    tabSliderInit
  );

  // const accordElem = React.useRef(null);

  const imageToDisplay = slides[currentTabState.activeIndex].image;

  const textToDisplay = slides[currentTabState.activeIndex].title;

  const allTitles = slides.map((slide) => slide.title);

  const allImages = slides.map((slide) => slide.image);
  usePreloadImages(allImages);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      incrementOffset();
    },
    onSwipedRight: () => {
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // const total = slides.length; // the length of the slide array
  // const enabled = true; // pauses/disables the player
  // const useLoaded = false; // this allows for delayed loads like images or embeds
  // // const speed = 10000; // speed in milliseconds to show each slide
  // const loop = true;

  // const [offset, setOffset] = React.useState(0);
  // const [items, setItems] = React.useState([]);

  function incrementOffset() {
    dispatchTabAction({ type: "next" });
  }

  function decrementOffset() {
    dispatchTabAction({ type: "prev" });
  }
  return (
    <>
      <h3 className="font-section-normal-text-medium-link">
        Drive efficiency in your IT Helpdesk with employee service automation
      </h3>
      <p className="font-section-normal-text-testimonials line-height-18">
        Workativ’s one-click integration and out of the box workflows with your
        existing apps will enable true AI self-service automation. Automate
        employee issues & requests such as &nbsp;
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 0 })}
        >
          Salary Enquires
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 1 })}
        >
          Apply Leave
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 2 })}
        >
          Expense Claims
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 3 })}
        >
          Policy Searches
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 5 })}
        >
          {" "}
          employee onboarding
        </span>
        ,{" "}
        <span
          className="external_slider_links"
          onClick={() => dispatchTabAction({ type: "to", toTab: 6 })}
        >
          {" "}
          employee offboarding
        </span>{" "}
        exit feedbacks and much more.
      </p>
      <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0 color-light-black">
        Make self-service meaningful. Add app workflows so your self-service can
        auto-resolve issues.
      </p>

      <div className="right_container_tab_slider_it_suuport" {...handlers}>
        <img className="w-75 m-auto" src={imageToDisplay}></img>

        <div className="it_app_workflow_mobile_tab_slider">
          <img
            src={arrow_round_mobile}
            className="support_tab_left_arrow_mobile"
            onClick={() => dispatchTabAction({ type: "prev" })}
          ></img>
          <h5 className="font-section-normal-text-medium  mb-0">
            {textToDisplay}
          </h5>
          <img
            src={arrow_round_mobile}
            className="support_tab_right_arrow_mobile"
            onClick={() => dispatchTabAction({ type: "next" })}
          ></img>
        </div>
      </div>
    </>
  );
}
